<template>
    <div class="modal-backdrop" v-if="showModal">
      <div class="modal-content">
        <div class="modal-backdrop__header">
          <h3>User login</h3>
          <button class="close-button" @click="closeModal">
            <span class="material-symbols-rounded">close</span>
          </button>
        </div>
        <!-- Contenido de tu modal aquí -->
        <!-- desktop form -->
        <!-- <form class="login__form desktop" name="LoginForm" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            
            <div class="login__form--item">
            <label class="login__form--label" for="username">Username</label>
            <input class="login__form--input" type="text" placeholder="USERNAME" name="account" id="account"/>
          </div>
  
          <div class="login__form--item">
            <label class="login__form--label" for="password">Password</label>
            <input class="login__form--input" type="password" name="password" placeholder="PASSWORD" id="password"/>
          </div>
  
          <div class="login__btns-login">
            <input type="submit" class="primary-button primary-button__loggin" >
          </div>
        </form> -->

        <!-- mobile form -->
        <form class="login__form2 " method="post" :action="`//mobile.${BackEndUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" value="975" />
            <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />
            
          <div class="login__form--item">
            <!-- <label class="login__form--label" >Username</label> -->
            <input class="login__form--input" type="text" placeholder="USERNAME" name="account" id="account"/>
          </div>
  
          <div class="login__form--item">
            <!-- <label class="login__form--label" >Password</label> -->
            <input class="login__form--input" type="password" placeholder="PASSWORD" name="password" id="password"/>
          </div>
  
          <div class="login__btns-login">
            <button type="submit" class="primary-button primary-button__loggin" >Enviar</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'ModalClassic',
      setup:() => {
        const BackEndUrl = "luckycrawfish.live";
        
        return {
          BackEndUrl
        }
      },
      data() {
        return {
          showModal: false
        }
      },
      methods: {
        openModal() {
          this.showModal = true;
        },
        closeModal() {
          this.showModal = false;
        }
      }
    }
  </script>